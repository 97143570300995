@import "sass-variables.scss";
.slideWrapper {
  width: 100%;
  max-width: 680px;
  container-type: inline-size;
}

.slide {
  position: relative;
  border-radius: 12px;
  overflow: hidden !important;
  aspect-ratio: 1.1;
  box-shadow:
    0 12px 40px -15px rgba(0, 0, 0, 0.2),
    0 15px 6px -10px rgba(0, 0, 0, 0.02);

  @container (min-width: 550px) {
    aspect-ratio: 1.3;
  }

  &:has(.expandedInfo) {
    cursor: pointer;
  }
}

.imgWrapper {
  position: absolute;
  inset: 0;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background-image: linear-gradient(to top,
        rgba(250, 250, 250, 0.9) 2%,
        rgba(250, 250, 250, 0.4) 15%,
        transparent 30%);
  }
}

.content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  padding-inline: 3% 4%;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.title {
  text-align: right;
  font-weight: var(--font-weight-accent-bold);
  font-size: 1.35rem;
  opacity: .9;
  text-shadow: 0 .5px 0 #fff;
  letter-spacing: -0.015em;

  @container (min-width: 450px) {
    font-size: 1.45rem;
  }

  @container (max-width: 399px) {
    display: none;
  }
}

.logoWrapper {
  flex-basis: 25%;
  min-width: 120px;
  max-width: 200px;
  margin-block: -12px;

  >img {
    display: block;
  }
}

.logoImg--overfill {
  filter: brightness(0) invert(1);
}

.caption {
  font-family: var(--font-family-primary);
  text-transform: uppercase;
  font-size: 1.3rem;
  color: rgba(255, 255, 255, 0.80);
  letter-spacing: 0.03em;
  text-align: right;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
}

.expandedInfo {
  position: absolute;
  width: 60%;
  top: 0;
  bottom: 0;
  right: 0;

  z-index: 100;
  background: rgba(0, 0, 0, .6);
  color: #fff;
  padding: 40px 3% 20px;

  background: #1D2B59;
  background-image: radial-gradient(circle at 49% 87%, rgba(15, 16, 20, 0), rgba(15, 16, 20, 0.80) 60%);
}