@import "sass-variables.scss";
.chip {
  --border-radius: 6px;
  @media (min-width: $mq-medium) {
    --border-radius: 8px;
  }
  border-radius: var(--border-radius);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  aspect-ratio: 2.4;
  width: 250px;

  box-shadow: 0 4px 20px -2px rgba(0, 0, 0, 0.1);
  position: relative;

  &::after {
    content: "";
    border: 1px solid #E8E8E8;
    position: absolute;
    inset: 0;
    border-radius: var(--border-radius);
    mix-blend-mode: multiply;
  }
}

a > .chip:hover {
  outline: 1px solid var(--color-alt-tint);
  background-color: #fafafa;

  &::after {
    border-color: var(--color-alt-tint);
    mix-blend-mode: normal;
  }
}

a.supplierLink {
  position: relative;

  >.chip:hover::before {
    content: "Supplier Website";
    position: absolute;
    bottom: 1px;
    right: 1px;
    display: block;
    background-color: rgba(0,0,0,.5);
    color: #fff;
    padding: 2px 8px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    font-size: 1.2rem;
    text-shadow: 0 1px 1px rgba(0,0,0,.2)
  }
}

.logoImg {
  max-width: 86%;
  max-height: 90%;
  object-fit: contain;
}