@import "sass-variables.scss";
.section {
  position: relative;
  background: rgba(0,0,0,.75);
  color: #fff;
}

.bgImg {
  mix-blend-mode: multiply;
  object-fit: cover;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block: 80px 50px;
  height: 800px;
  overflow: hidden;
}

.contentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  width: 100%;
}

.page {
  position: absolute;
  display: flex;
  padding-inline: 40px;

  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}

.testimonial {
  max-width: 580px;

  color: var(--color-alt-tint-lighter);

  &__highlight {
    font-family: var(--font-family-accent);
    font-size: 2.6rem;

    &::before {
        content: "“";
      }
    &::after {
      content: "”";
    }
  }

  &__body  {
    >:first-child::before {
      content: "“";
    }
    >:last-child::after {
      content: "”";
    }
    margin-top: 1.8em;
  }

  &__readMore {
    margin-top: 15px;
    text-decoration: underline;
    text-decoration-color: rgba(255,255,255,.4);
  }

  &__name {
    font-family: var(--font-family-accent);
    font-weight: var(--font-weight-accent-bold);
    font-size: 1.4rem;
    margin-top: 1.4em;
  }
}

.next,
.prev {
  top: calc(50% - 25px);
  position: absolute;
  background-image: linear-gradient(180deg, #848484, #5D5D5D);
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.5);
  border-radius: 999px;
  width: 50px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 48px;
  line-height: 0;
  z-index: 2;
  color: var(--color-darkest);

  &:active {
    background-image: linear-gradient(180deg, #949494, #6D6D6D);
  }

  @media (max-width: ($mq-medium - 1px)) {
    display: none;
  }
}

.next {
  right: 20px;
  @media (min-width: $mq-large) {
    right: 100px;
  }
}

.prev {
  transform: scaleX(-1);
  left: 20px;
  @media (min-width: $mq-large) {
    left: 100px;
  }
}

.pipsContainer {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
}

.pip {
  width: 14px;
  aspect-ratio: 1;
  background: linear-gradient(180deg, #848484, #5D5D5D);
  transition: background .2s ease;
  border-radius: 999px;
  opacity: .8;
  cursor: pointer;
}

.pipActive {
  background: var(--color-alt-tint-light);
  background: linear-gradient(180deg, var(--color-alt-tint-light), var(--color-alt-tint-light));
}

.moreButton {
  background: rgba(219, 239, 255, 0.15);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(151, 151, 151, 0.2);

  &:hover {
    background: rgba(219, 239, 255, 0.2);
  }
}