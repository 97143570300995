@import "sass-variables.scss";
.wrapper {
  overflow: hidden;
  position: relative;
  min-height: 100%;
  width: 100%;
}

.innerWrapper {
  position: absolute;
  inset: 0;
}

.content {
  position: absolute;
  inset-inline: 0;
  bottom: 0;
  z-index: 100;
}