@import "sass-variables.scss";
.wrapper {
  overflow: hidden;
  position: relative;
  padding-block: 80px;
  background: linear-gradient(to bottom, rgba(255,255,255,.5), rgba(255,255,255,0)) #F7F7F7;
  border-top: 1px solid #E8E8E8;
  perspective: 2000px;
}

.logosWrapper {
  transform: rotateY(-10deg) rotateX(3deg);
  margin-left: -30px; // full measure
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.05) 100px, black 60%);
  @media (min-width: $mq-medium) {
    margin-left: auto;
    width: calc(100% - 400px);
    max-width: 70%;
    mask-image: linear-gradient(to right, transparent, rgba(0,0,0,.4) 20%, black calc(50%))
  }
}

.contentWrapper {
  @media (max-width: ($mq-medium - 1px)) {
    margin-top: -80px;
  }
  @media (min-width: $mq-medium) {
    position: absolute;
    inset: 0;
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.contentContainer {
  flex: 0 0 100%;
}

.content {
  max-width: 440px;

  @media (max-width: ($mq-medium - 1px)) {
    margin-inline: auto;
    text-align: center;
  }

  p:first-of-type {
    margin-top: .8em;
  }

  a {
    margin-top: 20px;
    display: inline-block;
  }
}