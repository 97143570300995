@import "sass-variables.scss";
.track {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
  justify-content: center;

  @media (min-width: $mq-large) {
    gap: 12px;
    padding-bottom: 14px;
  }

  >* {
    flex: 0 0 1fr;
    min-width: 150px;
    max-width: 250px;
  }
}