@import "sass-variables.scss";
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 80px 30px;
  padding-block: 120px;

  @media (min-width: $mq-medium) {
    padding-block: 180px;
  }
}

.ctaContainer {
  text-align: center;
  flex: 0 1 440px;

  h3 {
    color: var(--color-primary);
    margin-bottom: 15px;
  }
}

// Slider

.sliderContainer {
  --line-height: 1.25em;
  margin-left: -.3em; // visually balance

  display: flex;
  align-items: center;
  gap: .4em;

  font-size: 2.5rem;
  @media (min-width: $mq-medium) {
    font-size: 3.2rem;
  }


  font-family: var(--font-family-accent);
  line-height: var(--line-height);
  text-transform: lowercase;
  color: var(--color-primary);

  ul {
    transform-origin: 0% 50%;
    font-weight: var(--font-weight-accent-bold);
    height: calc(var(--line-height) * 7);

    // li:hover {
    //   transition: all .5s;
    //   filter: blur(0px);
    //   color: var(--color-primary-darker);
    //   transform: scale(1.02) !important;
    // }

    >*:nth-child(4) {
      color: var(--color-primary-darker);
    }

    >*:nth-child(3), >*:nth-child(5)  {
      filter: blur(0.5px);
      opacity: .9;
    }
    >*:nth-child(2), >*:nth-child(6)  {
      filter: blur(1px);
      opacity: .8;
    }
    >*:nth-child(1), >*:nth-child(7)  {
      filter: blur(1.7px);
      opacity: .7;
    }
  }
}