@import "sass-variables.scss";
.wrapper {
  padding-block: 80px;
  background: linear-gradient(
    to bottom, #fafafa, var(--color-alt-tint-light) 150px);
  border-top: 1px solid #fff;
  border-bottom: 1px solid #eaeaea;
}

.container {
  margin-inline: var(--container-padding-inline);
}

.header {
  text-align: center;
  margin-bottom: 50px;
}

.itemsContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  justify-content: center;
  justify-items: center;

  @media (min-width: $mq-medium) {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }

  @media (min-width: $mq-medium) and (max-width: ($mq-xxl - 1px)) {
    >:nth-child(3) {
      display: none;
    }
  }
}

.moreTextContainer {
  max-width: 700px;
  margin-inline: auto;
  margin-top: 50px;
  text-align: center;
}