@import "sass-variables.scss";
.homeIntro {
  margin: 50px auto 80px;

  max-width: $mq-xxl;
  padding-inline: var(--container-padding-inline);

  display: grid;

  min-height: 540px;

  @media (min-width: $mq-large) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr auto;
  }

  gap: 30px;
  @media (min-width: $mq-medium) {
    gap: 15px;
  }
}

.header {
  grid-column: 1;
  grid-row: 1;

  align-self: center;

  @media (max-width: ($mq-large - 1px)) {
    text-align: center;
  }

  @media (min-width: $mq-medium) {
    padding-block: 40px;
  }

  @media (min-width: $mq-large) {
    padding-left: 60px;
    padding-right: 40px;
  }
}

.heading {
  line-height: 1.2;
  margin-bottom: 10px !important;
  letter-spacing: -0.03em;
  background: linear-gradient(var(--color-primary), var(--color-primary-darker));
  background-clip: text;
  font-weight: var(--font-weight-accent-bold);
  text-wrap: balance;
  -webkit-text-fill-color: transparent;

  @media (max-width: ($mq-medium - 1px)) {
    font-size: 2.6rem;
  }
}

.hookText {
  color: var(--color-primary-subtle);
  margin-left: .1em; // visually align with heading

  display: inline-flex;
  @media (max-width: ($mq-large - 1px)) {
    justify-content: center;
  }
  flex-wrap: wrap;
  // white-space: nowrap

  text-decoration-color: var(--color-alt-tint-light);
  text-underline-offset: .4em;
  &:hover {
    color: var(--color-alt);
  }
  &:link {
    text-decoration: underline;
  }

  >span {
    flex: 0 1 auto;
    white-space: nowrap;

    @media (min-width: 420px) {
      &:not(:last-child)::after {
        content: "|";
        color: var(--color-alt);
        margin-inline: .7em;
      }
    }
  }
}

.panel {
  background: var(--color-alt-tint-light);
  padding: 40px 30px;

  @media (min-width: $mq-large) {
    grid-column: 1;
    grid-row: 2;

    padding-left: 60px;
    padding-right: 40px;
  }
}

.panelContent {
  max-width: 600px;

  @media (max-width: ($mq-medium - 1px)) {
    h2 {
      letter-spacing: -.02em;
      font-size: 2rem;
      margin-bottom: 1em !important;
    }
  }

  @media (max-width: ($mq-large - 1px)) {
    margin-inline: auto;
    text-align: center;
  }

  @media (min-width: $mq-large) {
    max-width: 480px;

    h2 {
      max-width: 440px;
    }
  }

  h2 {
    color: var(--color-primary);
  }
}

.imageWrapper {
  display: flex;
  height: 100%;
  max-width: 100%;

  aspect-ratio: 1.4;

  grid-column: 1;
  grid-row: 2;

  @media (min-width: $mq-large) {
    aspect-ratio: initial;
    grid-column: 2;
    grid-row: 1 / 3;
  }
}