@import "sass-variables.scss";
.creditTag {
  position: absolute;
  z-index: 100;

  display: flex;
  align-items: center;
  gap: .55em;

  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0 1px 2px rgba(0,0,0,.1);
  border-radius: 4px;
  backdrop-filter: blur(5px);

  padding: .7em 1.2em;

  color: var(--color-dark);
  font-size: 1.2rem;
  font-family: var(--font-family-accent);
  font-weight: var(--font-weight-accent-bold);
  letter-spacing: -0.01em;

  line-height: 1;
  white-space: nowrap;
  transition: color .2s;

  cursor: pointer;

  &:hover {
    color: var(--color-primary);

    svg {
      opacity: 1;
      transform: scale(1.05);
    }
  }

  svg {
    background-color: var(--color-alt);
    padding-block: .2em;
    padding-inline: .23em .17em;
    border-radius: 20%;
    color: #fff;
    margin-right: -.15em;
    opacity: .6;
    transform-origin: center left;
    transition: opacity .3s, transform .4s;
  }
}