@import "sass-variables.scss";
.infoRow {
  display: flex;
  justify-content: space-between;
  padding-block: 15px;

  background-image: linear-gradient(
    3deg,
    rgba(255, 255, 255, 0.5),
    transparent 50%
  );
}

.logoWrapper {
  min-width: 100px;
  max-width: 150px;
  margin-block: -15px;

  >img {
    display: block;
  }
}

// TODO merge with CrediTag
.titleTag {
  display: flex;
  align-items: center;
  gap: .6em;

  border-radius: 4px;
  backdrop-filter: blur(3px);
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
  color: var(--color-dark);

  padding: .7em 1.2em;

  font-size: 1.3rem;

  background: rgba(40, 40, 40, 0.6);
  color: rgba(255,255,255,.8);

  svg {
    opacity: .8;
    margin-left: -.2em;
  }
}

.title {
  font-family: var(--font-family-accent);
  font-weight: var(--font-weight-accent-bold);
  letter-spacing: -0.01em;

  line-height: 1;
  white-space: nowrap;
}